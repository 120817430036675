import { SearchIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Center,
  Flex,
  Grid,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorMode,
  useColorModeValue,Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import style from "../Style/Collection.module.css";
// import style from "../Style/Featured.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
// import textStyle from "../../Style/List.module.css";
// import LoadingAnimation from '../LoadingAnimation';

export const CurtedCollection_home = () => {
  let [data, setData] = useState([]);
  let [page, setPage] = useState(1);

  const { colorMode, toggleColorMode } = useColorMode();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const borderColor = useColorModeValue("#e0e0e0", "#444");

  const font = useColorModeValue("gray.600", "gray.400");
  const heading = useColorModeValue("gray.800", "#eeeeee");

  const getData = async () => {
    setLoading(true);
  
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/space/get/all_home?&page=${page}`
      );
  
      const fetchedData = res.data.data.space;
      console.log('Fetched Data:', fetchedData);
  
      const newData = fetchedData.filter(fetchedItem => 
        !data.some(existingItem => existingItem._id === fetchedItem._id)
      );
  
      console.log('New Data (filtered):', newData);
      setData(prevData => [...prevData, ...newData]);
      setHasMore(fetchedData.length > 0);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth >= 768);
  };

  useEffect(() => {
    getData();
  }, [page]);

  const slicedData = isDesktop ? data.slice(0, 3) : data.slice(0, 3);



  // if (loading) {
  //   return (
  //     <div style={{ marginTop: '-80px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
  //       <LoadingAnimation size="xl" />
  //     </div>
  //   );
  // }

  const bordercolor = useColorModeValue("#e0e0e0e6" , "#333333"); 

  return (
    <Box mt={7} fontFamily="'Lato', Arial, Helvetica, sans-serif" >
                         <Heading as="h2" textAlign="Left"   fontSize="lg" color='blue.400'   my={2}>
                         Curated Collections for Peak Productivity
             </Heading>
            <Text as="h3" fontSize="15px" color={font} mb={4}>
            Discover Your Next Favorite AI Tool through Our Expertly Curated Collections, Featuring the Best in AI Design, Development, and Efficiency Enhancers
            </Text>
      {/* <Text textAlign={{ base: "center", md: "justify" }} className={style.colldesc} fontSize={{ base: "16px", md: "20px" }} color={heading} >
      Explore Premier AI Tools: A Curated Selection by Experts and Users
      </Text>
  */}

      <Grid
        width={"100%"}
        gridTemplateColumns={{ base: "repeat(1,1fr)", lg: "repeat(3,1fr)" }}
        gridTemplateRows={"auto"}
        columnGap={"50px"}
        rowGap={"30px"}
        marginTop={{ base: 7, md: 7 }}
      >
        {slicedData.map((el, i) => (
          <Link to={`/collection/curated-collection/${el.slug}`} key={i}>
           <Flex 
             width={"100%"} 
               gap={5} 
                 _hover={{ 
               "& .hover-grid": { // Targeting the Grid within Flex
                borderColor: '#42a4ff',
                boxShadow: '0 0 10px #42a4ff'
                  }
                }}
            >
  <Grid
    className="hover-grid" // Add a class to the Grid
    width="100px"
    gridTemplateColumns={"repeat(2,1fr)"}
    gridTemplateRows={"repeat(2, 1fr)"} // Make it a 2x2 grid for symmetry
    gap={3}
    backgroundColor={colorMode === "light" ? "#DAE7FA" : "#2f3f48"}
    borderRadius={5}
    padding={4}
    ml={1}
    border={"1px"}
    color={bordercolor}
    height={"100px"}
          //boxShadow={"0px 3px 6px rgba(0,0,0,0.16)"} // Subtle shadow for depth

  >
                {el?.tool[0]?.logoURL ? (
                  <Image
                    src={`${el?.tool[0].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"65px"}
                    height={"auto"}
                    alt={`1st Tool's logo from collection`} 
                  />
                ) : (
                  // <Image
                  //   src={"https://www.svgrepo.com/show/530661/genetic-data.svg"}
                  //   borderRadius="4px"
                  //   width={"65px"}
                  //   height={"auto"}
                  // />
                  ""
                )}
                {el?.tool[1]?.logoURL ? (
                  <Image
                    src={`${el?.tool[1].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"65px"}
                    height={"auto"}
                    alt={`2nd Tool's logo from collection`} 
                  />
                ) : (
                  // <Image
                  //   src={
                  //     "https://www.svgrepo.com/show/530439/api-interface.svg"
                  //   }
                  //   borderRadius="4px"
                  //   width={"65px"}
                  //   height={"auto"}
                  // />
                  ""
                )}
                {el?.tool[2]?.logoURL ? (
                  <Image
                    src={`${el?.tool[2].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"65px"}
                    height={"auto"}
                    alt={`3rd Tool's logo from collection`} 
                  />
                ) : (
                  // <Image
                  //   src={
                  //     "https://www.svgrepo.com/show/530401/table-of-contents.svg"
                  //   }
                  //   borderRadius="4px"
                  //   width={"65px"}
                  //   height={"auto"}
                  // />
                  ""
                )}
                {el?.tool[3]?.logoURL ? (
                  <Image
                    src={`${el?.tool[3].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"65px"}
                    height={"auto"}
                    alt={`4th Tool's logo from collection`} 
                  />
                ) : 
                  // <Image
                  //   src={"https://www.svgrepo.com/show/530225/cell-phone.svg"}
                  //   borderRadius="4px"
                  //   width={"65px"}
                  //   height={"auto"}
                  // />
                  ""
                }
       
              </Grid>

              

              <Flex
                width={{ base: "70%", md: "80%", lg: "70%" }}
                flexDirection={"column"}
                gap={2}
                alignItems={"flex-start"}
              >
                <Box>
                  <Text
                    fontSize={{ base: "15px", md: "15px" }}
                    fontWeight={600}
                    color={heading}
                  >
                     {el?.space_name.length > 30
                     ? `${el?.space_name.substring(0, 28)}...`
                     : el?.space_name}
                  </Text>
                </Box>

                <Flex
                  alignItems={{ base: "flex-start", md: "center" }}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  {/* <Box paddingRight={2}>
                    <Text
                      fontSize={{ base: "12px", md: "15px" }}
                      color={"#666666"}
                      fontWeight={600}
                    >
                      Created by
                    </Text>
                  </Box> */}

                  <Flex alignItems={"center"}>
                    <Flex alignItems={"center"} gap={2} >
                      <Box >
                        <Image boxSize="24px"  borderRadius="full" loading="lazy" alt={"Profile picture"}   src={el?.userID?.image } />
                      </Box>
                      <Text
                        fontSize={{ base: "12px", md: "12px" }}
                        fontWeight={600}
                      >
                        {`${el?.userID?.name}`.length > 8
                          ? `${el?.userID?.name}`.substring(0, 7) + ".."
                          : `${el?.userID?.name}`}
                      </Text>
                    </Flex>

                    <Text
                        fontSize={{ base: "12px", md: "12px" }}
                        fontWeight={600}
                        px={1}
                        color={"#3b89b6"}
                      >-</Text>

                    
                      <Text
                        fontSize={{ base: "12px", md: "12px" }}
                        fontWeight={600}
                        color={"#3b89b6"}
                      >
                        {el?.tool.length}{" "}tools 
                      </Text>
                 
                  </Flex>
                </Flex>

                <Box display={{ base: "block", md: "none" }}>
                  <Text color={font} fontSize={{ base: "12px", md: "14px" }}>
                    {`${el?.description}`.length > 30
                      ? `${el?.description}`.substring(0, 30) + "..."
                      : `${el?.description}`}
                  </Text>
                </Box>

                <Box display={{ base: "none", md: "block" }}>
                  <Text color={font} fontSize={{ base: "12px", md: "14px" }}>
                    {`${el?.description}`.length > 50
                      ? `${el?.description}`.substring(0, 45) + "..."
                      : `${el?.description}`}
                  </Text>
                </Box>
              </Flex>        
          
            </Flex>
            
          </Link>
        ))}
              
       
      </Grid>

      <Center  py={4}>
      <Link to="/collection">
      <Button
  size="lg"           // Set a larger size if desired
  variant="outline"   // Choose variant as needed
  width="200px"       // Set a fixed width to prevent shifts
  height="50px"       // Set a fixed height
  paddingX="8"        // Control padding for consistency
>
      View All Collections
    </Button>
    </Link>
  </Center>
    </Box>
    
  );
  
};
