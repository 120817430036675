import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Textarea,
  Text,
  Image,
  Button,
  useColorMode,
  Tooltip,
  IconButton ,
  useColorModeValue ,

  
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { DiOpensource } from "react-icons/di";
import { AiFillApi } from "react-icons/ai";
import Select from "react-select";
import style from "../../Style/Submit.module.css";
import excel from "../../Utils/Spreadsheet_icon.svg";
import chatgpt from "../../Utils/ChatGPT.svg";
import shopify from "../../Utils/shopify.svg";
import vscode from "../../Utils/Vs code.svg";

import figma from "../../Utils/Figma.svg";
import github from "../../Utils/Github.svg";
import Web from "../../Utils/Web.svg";
import mobile from "../../Utils/Mobile app.svg";
import slack from "../../Utils/Slack.svg";
import browser from "../../Utils/Browser Extension.svg";
import Wordpress from "../../Utils/Wordpress.svg";
import { useState } from "react";
import { BsQuestionDiamondFill } from "react-icons/bs";
import { Link, useParams} from "react-router-dom";
import { useEffect } from "react";
let data = [
  {
    logo: Web,
    name: "Web",
  },
  {
    logo: excel,
    name: "Sheet/Excel",
  },
  {
    logo: shopify,
    name: "Shopify",
  },
  // {
  //     logo: chatgpt,
  //     name: "ChatGPT(Plugin)"
  // },
  {
    logo: vscode,
    name: "VS Code",
  },
  {
    logo: github,
    name: "Github",
  },
  {
    logo: slack,
    name: "Slack",
  },
  {
    logo: mobile,
    name: "Mobile app",
  },
  {
    logo: Wordpress,
    name: "Wordpress",
  },
  {
    logo: figma,
    name: "Figma",
  },
  {
    logo: browser,
    name: "Browser extension",
  },
];

let otherdata = [
  {
    logo: <AiFillApi size={18} />,
    name: "API",
  },
  {
    logo: <DiOpensource size={18} />,
    name: "Open Source",
  },
];

export const Form1 = ({
  works_with,
  others_features,
  setkey_features,
  URL,
  Title,
  Tagline,
  Description,
  key_features,
  
  social_media,
  setSocial_media,
  handlechange,
  Category,
  setCategory,
  setworks_with,
  underCategory,
  setothers_features,
  afilitedLink,
  Support,
}) => {
  const { colorMode } = useColorMode();

  const mandatoryTextColor = useColorModeValue ("lightShadeInLightMode", "lightShadeInDarkMode");

  const { id } = useParams();
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",

      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        colorMode === "dark" ? "#222222" : "var(--landing-page, #FFF)",

      cursor: "pointer",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: colorMode === 'dark' ? '#2D3748' : '#E2E8F0', // Choose your dark mode background color
      color: colorMode === 'dark' ? 'white' : 'black', // Choose your dark mode text color
      borderRadius: '4px', // Optional: if you want rounded corners
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: colorMode === 'dark' ? 'white' : 'black', // Text color inside the label
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: colorMode === 'dark' ? 'white' : 'black', // Text color of the remove icon
      ':hover': {
        backgroundColor: colorMode === 'dark' ? '#E53E3E' : '#FC8181', // Background color on hover
        color: 'white',
      },
    }),
    };

  
  let [c, setc] = useState([]);

  const handleCheckboxChange = (e, name) => {
    const { name: checkboxName } = e.target;
    if (checkboxName === "others_features")
      if (e.target.checked) {
        setothers_features([...others_features, name]);
      } else {
        setothers_features(others_features.filter((item) => item !== name));
      }
    else if (checkboxName === "works_with") {
      if (e.target.checked) {
        setworks_with([...works_with, name]);
      } else {
        setworks_with(works_with.filter((item) => item !== name));
      }
    }
  };

  const taglineMaxLength = 300;
  const descriptionMaxLength = 1000;

  useEffect(() => {
    if (key_features.length === 0) {
      setkey_features(["", "", ""]);
    }
  }, [key_features, setkey_features]);

  // Handle change for each key feature input
  const handleKeyFeatureChange = (index, value) => {
    const newKeyFeatures = [...key_features];
    newKeyFeatures[index] = value;
    setkey_features(newKeyFeatures);
  };

  // Add a new key feature input box
  const addKeyFeatureInput = () => {
    if (key_features.length < 10) {
      setkey_features([...key_features, ""]);
    }
  };

  const removeKeyFeature = (index) => {
    const newKeyFeatures = key_features.filter((_, i) => i !== index);
    setkey_features(newKeyFeatures);
  };


  const socialMediaArray = Array.isArray(social_media) ? social_media : [];

  useEffect(() => {
    // Initialize with three empty strings if array is empty
    if (social_media.length === 0) {
      setSocial_media(['', '', '']);
    }
  }, [social_media, setSocial_media]);
  
  const handleSocialMediaChange = (index, value) => {
    const updatedLinks = [...social_media];
    updatedLinks[index] = value;
    setSocial_media(updatedLinks);
  };
  
  const addSocialMediaInput = () => {
    setSocial_media([...social_media, '']);
  };
  
  const removeSocialMediaInput = (index) => {
    const updatedLinks = social_media.filter((_, i) => i !== index);
    setSocial_media(updatedLinks);
  };

  const selectedCategories = Category.map(cat => ({
    value: cat,
    label: cat
  }));

  return (
    <Box w="80%">
      {!id && <FormControl mt="15px" mb="40px">
        <FormLabel fontSize="20px" lineHeight="24px" fontWeight="400">
          Link to the tool
        </FormLabel>
        <Input
          value={URL}
          name="URL"
          onChange={handlechange}
          borderRadius="4px"
          w="100%"
          border={"1px"}
          borderStyle={""}
          borderColor="gray.400" // Sets a default border color.
          _hover={{ borderColor: "gray.500" }} // Changes border color on hover.
          _focus={{ 
            borderColor: "blue.500", // Changes border color on focus.
            boxShadow: "0 0 0 1px #3182ce" // Optional: Adds a shadow that matches the focus border color.
          }}
        />
      </FormControl>}

      <FormControl mt="15px" mb="40px">
        <FormLabel fontSize="20px" lineHeight="24px" fontWeight="400">
          <Flex alignItems="center" gap="8px">
            Affiliate dashboard link
            <Tooltip padding={5} borderRadius={10}label="Tools with affiliate links will be given priority placement on our website, which can help drive more traffic and generate more sign-ups for your tool and also helps us for keeping our website 100% free for everyone" aria-label="">
              <p>
                <BsQuestionDiamondFill size={15} />
              </p>
            </Tooltip>
          
          </Flex>
        </FormLabel>
        <Input
  value={afilitedLink}
  name="afilitedLink"
  onChange={handlechange}
  borderRadius="4px"
  borderColor="gray.400" // Sets a default border color.
  _hover={{ borderColor: "gray.500" }} // Changes border color on hover.
  _focus={{ 
    borderColor: "blue.500", // Changes border color on focus.
    boxShadow: "0 0 0 1px #3182ce" // Optional: Adds a shadow that matches the focus border color.
  }}
/>
      </FormControl>

      <FormControl mt="15px" mb="40px">
        <FormLabel fontSize="20px" lineHeight="24px" fontWeight="400">
          <Flex alignItems="center" gap="8px">
            Contact Email
           
          
          </Flex>
        </FormLabel>
        <Input
          value={Support}
          name="Support"
          onChange={handlechange}
          borderRadius="4px"
          borderColor="gray.400" // Sets a default border color.
          _hover={{ borderColor: "gray.500" }} // Changes border color on hover.
          _focus={{ 
            borderColor: "blue.500", // Changes border color on focus.
            boxShadow: "0 0 0 1px #3182ce" // Optional: Adds a shadow that matches the focus border color.
          }}
        />
      </FormControl>

      <FormControl mt="15px" mb="40px" isRequired>
        <Flex alignItems="center" justifyContent="start" gap="5px">
          <FormLabel fontSize="20px" lineHeight="24px" fontWeight="400">
            Name of the tool 
          </FormLabel> 
          <Text fontSize="13px" fontWeight="bold" color={mandatoryTextColor}>
         (Mandatory)
         </Text>
        </Flex>
        <Input
          value={Title}
          name="Title"
          onChange={handlechange}
          borderRadius="4px"
          placeholder="AI Zones "
          borderColor="gray.400" // Sets a default border color.
          _hover={{ borderColor: "gray.500" }} // Changes border color on hover.
          _focus={{ 
            borderColor: "blue.500", // Changes border color on focus.
            boxShadow: "0 0 0 1px #3182ce" // Optional: Adds a shadow that matches the focus border color.
          }}
        />
      </FormControl>

      <FormControl mt="15px" mb="40px" isRequired>
        <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" gap="5px">
        <FormLabel fontSize="20px" lineHeight="24px" fontWeight="400">
          Tagline
        </FormLabel>
        <Text fontSize="13px" textAlign="left "fontWeight="bold" color={mandatoryTextColor}>
         (Mandatory)
         </Text>
         </Flex>
        
     
        <Text fontSize="14px" >
          {Tagline.length} / {taglineMaxLength} 
        </Text>
        </Flex>
        <InputGroup>
        <Input
          value={Tagline}
          name="Tagline"
          onChange={handlechange}
          maxLength={taglineMaxLength}
          borderRadius="4px"
          placeholder="A snappy tagline that captures the essence of your tool"
          borderColor="gray.400" // Sets a default border color.
          _hover={{ borderColor: "gray.500" }} // Changes border color on hover.
          _focus={{ 
            borderColor: "blue.500", // Changes border color on focus.
            boxShadow: "0 0 0 1px #3182ce" // Optional: Adds a shadow that matches the focus border color.
          }}
        />
        </InputGroup>
      </FormControl>
      <FormControl mt="15px" mb="40px" isRequired>
      <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center" gap="5px">
      <FormLabel fontSize="20px" lineHeight="24px" fontWeight="400">
      Description
        </FormLabel>
        <Text fontSize="13px" textAlign="left "fontWeight="bold" color={mandatoryTextColor}>
         (Mandatory)
         </Text>
         </Flex>
          <Text fontSize="14px" fontWeight="400">
            {Description.length} / {descriptionMaxLength}
          </Text>
        </Flex>
        <Textarea
          value={Description}
          name="Description"
          onChange={handlechange}
          maxLength={descriptionMaxLength}
          borderRadius="4px"
          placeholder="Detailed description of the tool"
          rows={5}
          borderColor="gray.400" // Sets a default border color.
          _hover={{ borderColor: "gray.500" }} // Changes border color on hover.
          _focus={{ 
            borderColor: "blue.500", // Changes border color on focus.
            boxShadow: "0 0 0 1px #3182ce" // Optional: Adds a shadow that matches the focus border color.
          }}
        />

      </FormControl>
    {console.log(key_features)}
      <FormControl mt="15px" mb="40px">
        <FormLabel fontSize="20px" lineHeight="31px" fontWeight="400">
          <Flex alignItems="center" gap="8px">
            Key Features
            <Tooltip padding={5} borderRadius={10}label="Provide 2 to 5 key features of your tool. Each point should be clear and concise to help users understand the unique value of your tool." aria-label="">
              <p>
                <BsQuestionDiamondFill size={15} />
              </p>
            </Tooltip>
          </Flex>
        </FormLabel>
        {key_features.map((feature, index) => (
          <Flex key={index} alignItems="center" mb="10px">
            <Input
              value={feature}
              onChange={(e) => handleKeyFeatureChange(index, e.target.value)}
              borderRadius="4px"
              placeholder={`Feature ${index + 1}`}
              mr="10px"
              borderColor="gray.400" // Sets a default border color.
              _hover={{ borderColor: "gray.500" }} // Changes border color on hover.
              _focus={{ 
                borderColor: "blue.500", // Changes border color on focus.
                boxShadow: "0 0 0 1px #3182ce" // Optional: Adds a shadow that matches the focus border color.
              }}
            />
            {key_features.length > 3 && (
              <IconButton
                aria-label="Remove feature"
                icon={<DeleteIcon />}
                onClick={() => removeKeyFeature(index)}
                size="sm"
              />
            )}
          </Flex>
        ))}
        {key_features.length < 10 && (
          <Button onClick={addKeyFeatureInput} mt="10px" variant="ghost">
            <Text fontSize="20px">+</Text> Add More Feature
          </Button>
        )}
      </FormControl>


      <FormControl mt="15px" mb="40px">
      <Flex alignItems="center" gap="8px">
    Social Media Links
    <Tooltip padding={5} borderRadius={10}label="While we routinely update our system to include new and popular platforms, your input is invaluable in helping us stay current. If you come across a social media platform that you believe should be included in AI Zones, please don't hesitate to let us know. You can inform us about the new platform by sending an email to hello@aizones.io. In your email, please include the name of the social media platform and, if possible, a brief description of its features and popularity." aria-label="">
              <p>
                <BsQuestionDiamondFill size={15} />
              </p>
            </Tooltip>
  </Flex>
  {social_media.map((link, index) => (
    <Flex key={index} alignItems="center" mb="10px">
      <Input
        value={link}
        onChange={(e) => handleSocialMediaChange(index, e.target.value)}
        borderRadius="4px"
        placeholder={["Facebook URL","Linkedin URL", "Instagram URL", "YouTube URL", "Twitter URL"][index] || "Social media URL"}
        mr="10px"
        borderColor="gray.400" // Sets a default border color.
        _hover={{ borderColor: "gray.500" }} // Changes border color on hover.
        _focus={{ 
          borderColor: "blue.500", // Changes border color on focus.
          boxShadow: "0 0 0 1px #3182ce" // Optional: Adds a shadow that matches the focus border color.
        }}
      />
      {social_media.length > 3 && (
        <IconButton
          aria-label="Remove link"
          icon={<DeleteIcon />}
          onClick={() => removeSocialMediaInput(index)}
          size="sm"
        />
      )}
    </Flex>
  ))}
  <Button onClick={addSocialMediaInput} mt="10px" variant="ghost">
    <Text fontSize="20px">+</Text> Add More Links
  </Button>
</FormControl>

      <FormControl mt="15px" mb="40px">
      <Flex alignItems="center" gap="8px">
          Category (Max 2 Categories)
          <Tooltip padding={5} borderRadius={10}label="If your desired category is not in the list selet others or miscellaneous and inform us about you desired category at hello@aizones.io" aria-label="">
              <p>
                <BsQuestionDiamondFill size={15} />
              </p>
            </Tooltip>
               
        <Text fontSize="13px" textAlign="left "fontWeight="bold" color={mandatoryTextColor}>
         (Mandatory at least 1 category)
         </Text>
        
        </Flex>

        <Select
 value={selectedCategories}
  styles={customStyles}
  closeMenuOnSelect={false}
  isMulti
  options={underCategory}
  onChange={(selectedOptions) => {
    // Update the state to reflect the new set of selected options
    setc(selectedOptions);
    // Map over selectedOptions to create an array of values
    const selectedValues = selectedOptions.map(option => option.value);
    // Update the Category state with the new array
    setCategory(selectedValues);
  }}
  isOptionDisabled={() => Category.length >= 2} // This might need to be adjusted if you want to allow adding after removing to have 2 again
/>
      </FormControl>
      <FormControl mt="15px" mb="40px">
        <FormLabel fontSize="20px" lineHeight="24px" fontWeight="400">
          Works with
        </FormLabel>
        <Box className={style.integration}>
          {data?.map((el, i) => (
             <label key={i} className={style.integrationLabel}>
            <Flex
              key={i}
              justifyContent="space-between"
              border="1.3px solid #CCCCCC"
              alignItems="center"
              py={1}
              borderRadius="5px"
              fontSize="14px"
              px={2}
            >
              <Flex alignItems="center" gap="10px" padding={2} px={3}>
                <Image boxSize="22px" src={el.logo} />
                {<Text>{el.name}</Text>}
              </Flex>

              <input
              
                name="works_with"
                checked={works_with && works_with.includes(el.name)}
                onChange={(e) => handleCheckboxChange(e, el.name)}
                type="checkbox"
              />
            </Flex>
            </label>
          ))}
        </Box>
      </FormControl>

      <FormControl mt="15px" mb="40px">
        <FormLabel fontSize="20px" lineHeight="24px" fontWeight="400">
          Others
        </FormLabel>

        <Box display="flex" gap="20px">
          {otherdata?.map((el, i) => (
             <label key={i} className={style.integrationLabel}>
            <Flex
              key={i}
              alignItems="center"
              justifyContent="space-between"
              border="1.3px solid #CCCCCC"
              gap="20px"
              py={1}
              borderRadius="5px"
              fontSize="14px"
              px={2}
            >
              <Flex alignItems="center" gap="10px" padding={2} px={3}>
                <Box>{el.logo}</Box>
                {<Text>{el.name}</Text>}
              </Flex>

              <input
                name="others_features"
                checked={others_features && others_features.includes(el.name)}
                onChange={(e) => handleCheckboxChange(e, el.name)}
                type="checkbox"
              />
            </Flex>
            </label>
          ))}
        </Box>
      </FormControl>
    </Box>
  );
};
