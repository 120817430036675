import React from 'react';
import {
  Box,
  Container,
  Flex,
  Text,
  Link,
  Divider,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';


const Footer_options = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const borderColor = useColorModeValue('gray.300', 'gray.600');
  const bgColor = useColorModeValue('gray.50', '#222222');

  return (
    <Box as="footer" width="full" py={5}  borderColor={borderColor}>
      <Container maxW="container.xl">
        {/* <Divider my={4} /> */}
        <Flex
          direction={{ base: 'column', md: 'row' }}
          align="center"
          justify="space-between"
          wrap="wrap"
        >
            <Text color={linkColor} fontSize="sm" textAlign="center" mb={{ base: 4, md: 0 }}>
            © 2024 AI ZONES
          </Text>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={4} mb={{ base: 4, md: 0 }}>
            <Link href="/collection" color={linkColor} fontSize="sm">Collection</Link>
            <Link href="/top10" color={linkColor} fontSize="sm">Top 10</Link>
            <Link href="https://www.passionfroot.me/ai-zones" isExternal color={linkColor} fontSize="sm">Advertise</Link>
            <Link href="/submit" color={linkColor} fontSize="sm">Submit</Link>
            <Link href="/privacy" color={linkColor} fontSize="sm">Terms</Link>
            <Link href="/About" color={linkColor} fontSize="sm">About Us</Link>         
            <Link href="mailto:hello@aizones.io" color={linkColor} fontSize="sm">Support</Link>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
            <Link href="https://x.com/Aizones_io" isExternal color='blue.400'>
              X/Twitter
            </Link>    
            <Link href="https://www.instagram.com/aizones.io/" isExternal color='blue.400'>
              Instagram
            </Link>
            <Link href="https://www.linkedin.com/company/ai-zones" isExternal color='blue.400'>
              LinkedIn
            </Link>
          </Stack>
          
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer_options;
