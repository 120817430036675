import { Box, Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import style from "../Style/Featured.module.css";
import { RiShareBoxFill } from 'react-icons/ri';
import axios from 'axios';
import { Link } from 'react-router-dom';

export const Tranding = () => {
    let [data, setData] = useState([]);
    //const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const borderColor = useColorModeValue("#e0e0e0", "#444"); // Call this hook at the top level

    const heading = useColorModeValue("gray.800", "#eeeeee");
    const font = useColorModeValue("gray.600", "gray.400");

    const getData = async () => {

        // if (isMobile) {
        //     // Don't fetch data if on a mobile device
        //     return;
        // }
        try {
            let res = await axios.get(`${process.env.REACT_APP_API}/data/top-weekly-score-tools`);
            setData(res.data.data);
        } catch (err) {
            // Handle error
        }
    };

    // useEffect(() => {
    //     const handleResize = () => {
    //         setIsMobile(window.innerWidth < 768);
    //     };

    //     window.addEventListener("resize", handleResize);
    //     handleResize(); // Initial call

    //     return () => window.removeEventListener("resize", handleResize);
    //     }, []);


    useEffect(() => {
        getData();
    }, []); // Fetch data on component mount

    // useEffect(() => {
    //     getData();
    // }, [isMobile]); // Depend on isMobile to fetch data

    // if (isMobile) {
    //     // Don't render anything if on a mobile device
    //     return null;
    // }


    return (
        <div>
            <Box className={style.featured}>
                <Box>
                    <Text textTransform="uppercase" mt="30px" as="h3" fontWeight="600" fontSize="12px" color={heading} lineHeight="20px">Trending</Text>
                    {
                        data?.slice(0, 6).map((el, i) => (
                            <Box key={i} mt="30px">
                                <Flex alignItems="center" gap="10px">
                                    <Link to={`/tool/${el.slug}`}>
                                        <Box boxSize="40px">
                                            {/* <Image boxSize="100%" alt={`${el.Title} logo`} borderRadius="4px" src={el.Logo} /> */}

                                            <Image boxSize="100%" alt={`${el.Title} logo`} borderRadius="4px" 
                      width="100%"  // Image will scale to fill the width of the container
                      height="40px" // Fixed height for the image
                      objectFit="contain"
                  src={`${el?.Logo}?height=40`} />
                                        </Box>
                                    </Link>
                                    <Box>
                                        <Flex w="100%" alignItems="center" justifyContent="space-between">
                                            <Link to={`/tool/${el.slug}`}>
                                                <Text color={heading} fontSize="13px" fontWeight="600" lineHeight="24px" as="h4">{el.Title}</Text>
                                            </Link>
                                            <Link to={el.URL} target="_blank">
                                                <RiShareBoxFill size={14} aria-label="View Product Page"/>
                                            </Link>
                                        </Flex>
                                        <Link to={`/tool/${el.slug}`}>
                                        <Text mt="5px" w="90%" fontSize="12px" lineHeight="20px" color={font} as="h5" className={style.desc}>{el.Tagline}</Text>
                                        </Link>
                                        </Box>
                                </Flex>
                            </Box>
                        ))
                    }
                </Box>
            </Box>
        </div>
    );
};
