import React, { useState } from "react";
import tool from "../../Style/Tool.module.css";
import ReactStars from 'react-rating-stars-component';
import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  BsBoxArrowUpRight,
  BsClockHistory,
  BsFillFlagFill,
} from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { Tabcomp } from "./Tab";
import { FaStar, FaStarHalfAlt, FaRegStar   } from "react-icons/fa";
import { MdOutlineVerified } from "react-icons/md";
import { ImageBackground } from "../ImageBackground";
import { Icon } from '@chakra-ui/react';
import { FaFacebook, FaLinkedin, FaTwitter, FaYoutube, FaInstagram, FaReddit, FaMedium } from 'react-icons/fa';
// import Collect from "./Collect";
import excel from "../../Utils/Spreadsheet_icon.svg";
import chatgpt from "../../Utils/ChatGPT.svg";
import vscode from "../../Utils/Vs code.svg";
import figma from "../../Utils/Figma.svg";
import github from "../../Utils/Github.svg";
import mobile from "../../Utils/Mobile app.svg";
import slack from "../../Utils/Slack.svg";
import Web from "../../Utils/Web.svg";
import browser from "../../Utils/Browser Extension.svg";
import Wordpress from "../../Utils/Wordpress.svg";
import sopify from "../../Utils/sopify.svg";
import { DiOpensource } from "react-icons/di";
import { AiFillApi, AiFillDollarCircle, AiFillGift } from "react-icons/ai";
import { Likes } from "../Likes";
import axios from "axios";
import notification from "../Toast";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveCollectButton from "./ResponsiveCollectButton";
import ShareModel from "../Share";
import { setFilterCategory } from "../../Redux/action";
import Save from "../Home/Save";
import HandleGoogle from '../../Firebase/handleGoogleAuth';
import { useBreakpointValue } from '@chakra-ui/react';
import { FaXTwitter } from "react-icons/fa6";


export const Telement = ({ el, id, Title, handleClose, name }) => {
  let url = `${window.location.origin}/tool/${el?.slug}`;
  // let url = `${process.env.REACT_APP_PUBLIC_URL}/tool/${el?._id}`;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [review, setreview] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [more, setMore] = useState("");
  const [showDetails, setShowDetails] = useState(false);

  const imageUrl = el.Logo;
  const userData = useSelector((state) => state.userReducer.loginData);

  const heading = useColorModeValue("gray.800", "#eeeeee");
  const font = useColorModeValue("gray.600", "gray.400");

  const isBase = useBreakpointValue({ base: true, md: false });
  

  const sendReport = () => {
    const payload = {
      videoID: id,
      more: more,
      problem: selectedValue,
    };
    const token = userData.data;
    axios
      .post(`${process.env.REACT_APP_API}/report/add`, payload, {
        headers: { token },
      })
      .then((res) => {
        notification("success", res.data.msg);
        onClose();
      });
  };

  


  const handleReportClick = async () => {
    if (!userData) {
      // If the user is not logged in, use HandleGoogle for login
      await HandleGoogle(dispatch, navigate, null, onOpen);
    } else {
      // If the user is already logged in, just open the modal
      onOpen();
    }
  };

  const handleCategoryItem = (el) => {
    // console.log(el);
    dispatch(setFilterCategory(el));
    if (handleClose) {
      handleClose();
    } else {
      navigate("/");
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth", // Use 'auto' for instant scrolling, or 'smooth' for smooth scrolling
    });
  };

  //const RatingComponent = ({ review }) => {
    const getStars = (rating) => {
      const stars = [];
      for (let i = 1; i <= 5; i++) {
        if (rating >= i) {
          stars.push(<FaStar key={i} color="#ECBA67" size={14} />);
        } else if (rating >= i - 0.5) {
          stars.push(<FaStarHalfAlt key={i} color="#ECBA67" size={14} />);
        } else {
          stars.push(<FaRegStar key={i} color="rgb(192,192,192)" size={14} />);
        }
      }
      return stars;
    };

  return (
    <Box 
    // bg={useColorModeValue("var(--landing-page, #FFF)", "#222222")}
    >
      <Box className={tool.toolbox}>
        <Box display={{ base: "none", md: "block" }}>
          {/* <Box className={tool.titlehide2}> */}
            {/* <Flex justifyContent="space-between" alignItems="center">
              <Text fontSize="24px" as="h1" lineHeight="24px" fontWeight="600">
                {el?.Title}
              </Text>
              <Flex
                gap="10px"
                alignItems="center"
                fontStyle="16px"
                fontWeight="400"
                lineHeight="24px"
              >
                <Text>{el?.likes} People liked this</Text>
                <Box
                  border="1px solid #e0e0e0"
                  padding="8px"
                  borderRadius="9px"
                  justifyContent="center"
                >
                  <Likes el={el} />
                </Box>
              </Flex>
            </Flex> */}

             {/* <Flex gap="18px" alignItems="center" mt="15px" mb="10px"> */}
             {/* <Flex gap="4px">
                {[...Array(5)].map((item, index) => {
                  const givenRating = index + 1;
                  return (
                    <Box key={index}>
                      <FaStar
                        size={14}
                        value={givenRating}
                        color={
                          givenRating <= el?.rating
                            ? "#ECBA67"
                            : "rgb(192,192,192)"
                        }
                      />
                    </Box>
                  );
                })}
              </Flex> */}

              {/* <Flex
                alignItems="center"
                gap="5px"
                fontSize="15px "
                lineHeight="24px"
                fontWeight="400"
              >
                <Text fontWeight="700">{review}</Text>
                <Text>Reviews</Text>
              </Flex> */}
            {/* </Flex> */}

            {/* <Text
              mt="24px"
              mb="20px"
              fontSize="16px"
              fontWeight="600"
              lineHeight="24px"
              textAlign="justify"
              as="h2"
            >
              {el.Tagline}
            </Text> */}
          {/* </Box> */}

           <Stack className={tool.left}>
            <Box w="100%">
              <Box m="auto"  w="fit-content">
                <ImageBackground imageUrl={imageUrl} />
              </Box>

              <Box>
                <Box className={tool.visit} w="100%">
                  <Box>{console.log(el)}
                    <a href={el?.URL} target="_blank">
                      <Button
                        className={tool.vbutton}
                        _hover={{ bg: "" }}
                        color="white"
                        borderRadius="5px"
                        fontSize="13px"
                        fontWeight="400"
                        gap="7px"
                        alignItems="center"
                        h="36px"
                        bg="#3B89B6"
                        aria-label="View Product Page"
                      >
                        <BsBoxArrowUpRight /> Visit Site
                      </Button>
                    </a>
                  </Box>

                  <Box className={tool.vbutton}>
                    {/* <Collect el={el} id={id} /> */}
                    <Save  el={el}  width="100%"/>
                  </Box>
                </Box>
              </Box>

              <Box textAlign="left" w="100%">
                <Box className={tool.fprice}>
                {el.Pricing && el.Pricing !== "" && (
                  <Box>

                    <Text
                      mt="30px"
                      fontSize="13px"
                      lineHeight="24px"
                      fontWeight="600"
                    >
                      Pricing:
                    </Text>

                    <Flex
                      mt="10px"
                      flexDirection="column"
                      alignItems={"flex-start"}
                      fontSize="14px"
                      fontWeight="400"
                      color={font}
                      lineHeight="16px"
                      gap="10px"
                    >
                      {el.Pricing === "Free" ? (
                        <Flex alignItems="center" gap="10px">
                          <AiFillGift />
                          <Text>{el.Pricing}</Text>
                        </Flex>
                      ) : el.Pricing === "Free trial" ? (
                        <Flex alignItems="center" gap="10px">
                          <BsClockHistory />
                          <Text>{el.Pricing}</Text>
                        </Flex>
                      ) : el.Pricing === "Freemium" ? (
                        <Flex alignItems="center" gap="10px">
                          <MdOutlineVerified />
                          <Text>{el.Pricing}</Text>
                        </Flex>
                      ) : el.Pricing === "Paid" ? (
                        <Flex alignItems="center" gap="10px">
                          <AiFillDollarCircle />
                          <Text>{el.Pricing}</Text>
                        </Flex>
                      ) : (
                        ""
                      )}
                    </Flex>
                  </Box>
                    )}

                  {el?.price_amount && (
                    <Box>
                      <Text
                        mt="30px"
                        fontSize="13px"
                        lineHeight="24px"
                        fontWeight="600"
                      >
                        Starting price:{" "}
                      </Text>
                      <Text fontSize="13px" lineHeight="24px" color={font}>
                        {el?.price_amount}
                      </Text>
                    </Box>
                  ) }
                </Box>

                <Box className={tool.fwork}>
                  {(el?.works_with && [...new Set(el?.works_with)].length > 0 && [...new Set(el?.works_with)][0] !== "" ) ? (
                    <Box>
                      <Text
                        mt="30px"
                        fontSize="13px"
                        lineHeight="24px"
                        fontWeight="600"
                      >
                        Works with:
                      </Text>
                      <Flex
                        mt="10px"
                        flexDirection="column"
                        fontSize="14px"
                        fontWeight="400"
                        lineHeight="16px"
                        gap="10px"
                      >
                        {el?.works_with.map((e, i) => (
                          <Box key={i}>
                            {e.includes("Spreadsheet") ? (
                              <Flex alignItems="center" gap="10px">
                                <img
                                  w="15px"
                                  height="15px"
                                  alt="icon"
                                  src={excel}
                                />
                                <Text>{e}</Text>
                              </Flex>

) : e.includes("Web") ? (
  <Flex alignItems="center" gap="10px">
    <img
      w="15px"
      height="15px"
      alt="icon"
      src={Web}
      width={"15px"}
    />
    <Text>{e}</Text>
  </Flex>
  
                          
                            ) : e.includes("VS Code") ? (
                              <Flex alignItems="center" gap="10px">
                                <img
                                  w="15px"
                                  height="15px"
                                  alt="icon"
                                  src={vscode}
                                />
                                <Text>{e}</Text>
                              </Flex>
                            ) : e.includes("Github") ? (
                              <Flex alignItems="center" gap="10px">
                                <img
                                  w="15px"
                                  height="15px"
                                  alt="icon"
                                  src={github}
                                />
                                <Text>{e}</Text>
                              </Flex>
                            ) : e.includes("Mobile app") ? (
                              <Flex alignItems="center" gap="10px">
                                <img
                                  w="15px"
                                  height="15px"
                                  alt="icon"
                                  src={mobile}
                                />
                                <Text>{e}</Text>
                              </Flex>
                            ) : e.includes("Wordpress") ? (
                              <Flex alignItems="center" gap="10px">
                                <img
                                  w="15px"
                                  height="15px"
                                  alt="icon"
                                  src={Wordpress}
                                />
                                <Text>{e}</Text>
                              </Flex>
                            ) : e.includes("Figma") ? (
                              <Flex alignItems="center" gap="10px">
                                <img
                                  w="15px"
                                  height="15px"
                                  alt="icon"
                                  src={figma}
                                />
                                <Text>{e}</Text>
                              </Flex>
                            ) : e.includes("Browser Extension") ? (
                              <Flex alignItems="center" gap="10px">
                                <img
                                  w="15px"
                                  height="15px"
                                  alt="icon"
                                  src={browser}
                                />
                                <Text>{e}</Text>
                              </Flex>
                            ) : e.includes("Shopify") ? (
                              <Flex alignItems="center" gap="10px">
                                <img
                                  w="15px"
                                  height="15px"
                                  alt="icon"
                                  src={sopify}
                                />
                                <Text>{e}</Text>
                              </Flex>
                            ) : e.includes("Slack") ? (
                              <Flex alignItems="center" gap="10px">
                                <img
                                  w="15px"
                                  height="15px"
                                  alt="icon"
                                  src={slack}
                                />
                                <Text>{e}</Text>
                              </Flex>
                            ) : e.includes("chatGPT(Plugin)") ? (
                              <Flex alignItems="center" gap="10px">
                                <img
                                  w="15px"
                                  height="15px"
                                  alt="icon"
                                  src={chatgpt}
                                />
                                <Text>{e}</Text>
                              </Flex>
                            ) : (
                              ""
                            )}
                          </Box>
                        ))}
                      </Flex>
                    </Box>
                  ) : null}

                  {(el?.others_features && [...new Set(el?.others_features)].length > 0 && [...new Set(el?.others_features)][0] !== "") ? (
                    <Box>
                      <Text
                        mt="30px"
                        fontSize="13px"
                        lineHeight="24px"
                        fontWeight="600"
                      >
                        Other features:
                      </Text>
                      <Box mt="5px" gap="5px" fontSize="13px" fontWeight="400">
                        {el?.others_features?.map((e, i) => (
                          <Box key={i}>
                            {e.includes("API") ? (
                              <Flex gap="10px" alignItems="center">
                                <AiFillApi size={20} /> <Text>API</Text>
                              </Flex>
                            ) : e.includes("Open Source") ? (
                              <Flex gap="10px" alignItems="center">
                                <DiOpensource size={20} />{" "}
                                <Text>Open Source</Text>
                              </Flex>
                            ) : (
                              ""
                            )}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ) : null}
                </Box>

                <Box className={tool.fcate}>
                  <Box>
                    <Text
                      mt="30px"
                      fontSize="13px"
                      lineHeight="24px"
                      fontWeight="600"
                    >
                      Category:{" "}
                    </Text>

                    <Flex
                      flexDirection="column"
                      alignItems="left"
                      mt="7px"
                      gap="8px"
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="20px"
                      w="fit-content"
                    >
                      <Text
                        textAlign="center"
                        // border="1px"
                        // borderColor={useColorModeValue("#e0e0e0", "#444")}
                        // px="10px"
                        color={font}

                        borderRadius="12px"
                        w="fit-content"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleCategoryItem(el?.Category[0]);
                         
                        }}
                      >
                        #{el?.Category[0]}
                      </Text>
                      <Text
                        display={el.Category.length > 1 ? "block" : "none"}
                        fontSize="12px"
                        w="fit-content"
                        fontWeight="400"
                        lineHeight="20px"
                        // border="1px"
                        // borderColor={useColorModeValue("#e0e0e0", "#444")}
                        // px="10px"
                        color={font}

                        // borderRadius="12px"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleCategoryItem(el?.Category[1]);
                        }}
                      >
                        #{el?.Category[1]}
                      </Text>
                    </Flex>
                  </Box>
                      
                  {el?.Tags.length > 0 ? (
                    <Box>
                      <Text
                        mt="30px"
                        fontSize="13px"
                        lineHeight="24px"
                        fontWeight="600"
                      >
                        Tags:
                      </Text>
                      <Box fontStyle="italic" fontWeight="400" fontSize="12px">
                        {el?.Tags.map((e, i) => (
                          <Text mt="5px" color={font} fontSize="12px" key={i}>
                            {e}
                          </Text>
                        ))}
                      </Box>
                    </Box>
                  ) : null}
                </Box>

                {el?.Discount && (
                  <Box className={tool.fcate}>
                    <Box>
                      <Text
                        mt="30px"
                        fontSize="13px"
                        lineHeight="24px"
                        fontWeight="600"
                      >
                        Discount:
                      </Text>

                      <Flex
                        flexDirection="column"
                        alignItems="left"
                        mt="7px"
                        gap="8px"
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="20px"
                        w="fit-content"
                      >
                        <Text
                          textAlign="center"
                          border="1px"
                          borderColor={"#e0e0e0"}
                          px="10px"
                          borderRadius="12px"
                          w="fit-content"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {el?.Discount}
                        </Text>
                      </Flex>
                    </Box>

                    {el?.Tags > 0 ? (
                      <Box>
                        <Text
                          mt="30px"
                          fontSize="13px"
                          lineHeight="24px"
                          fontWeight="600"
                        >
                          Tags:
                        </Text>
                        <Box
                          fontStyle="italic"
                          fontWeight="400"
                          fontSize="12px"
                        >
                          {el?.Tags.map((e, i) => (
                            <Text color={font} mt="5px" fontSize="12px" key={i}>
                              {e}
                            </Text>
                          ))}
                        </Box>
                      </Box>
                    ) : null}
                  </Box>
                )}

                <Box className={tool.media}>
                  {(el?.social_media && [...new Set(el?.social_media)].length > 0 && [...new Set(el?.social_media)][0] !== "") ? (
                    <Box>
                      <Text
                        mt="30px"
                        fontSize="13px"
                        lineHeight="24px"
                        fontWeight="600"
                      >
                        Social media:
                      </Text>
                      <Flex
                        mt="5px"
                        gap="5px"
                        fontStyle="italic"
                        fontWeight="400"
                        fontSize="20px"
                      >
                         {el?.social_media.map((e, i) => (
          <Box key={i}>
            {e.includes("facebook") ? (
              <a href={e.trim()} target="_blank" rel="noopener noreferrer">
                <Icon as={FaFacebook} />
              </a>
            ) : e.includes("twitter") || e.includes("x.com") ? (
              <a href={e.trim()} target="_blank" >
                <Icon as={FaXTwitter} />
              </a>
            ) : e.includes("instagram") ? (
              <a href={e.trim()} target="_blank" rel="noopener noreferrer">
                <Icon as={FaInstagram} />
              </a>
            ) : e.includes("linkdin") ? (
              <a href={e.trim()} target="_blank" rel="noopener noreferrer">
                <Icon as={FaLinkedin} />
              </a>
            ) : e.includes("linkedin") ? (
              <a href={e.trim()} target="_blank" rel="noopener noreferrer">
                <Icon as={FaLinkedin} />
              </a>
            ) : e.includes("youtube") ? (
              <a href={e.trim()} target="_blank" rel="noopener noreferrer">
                <Icon as={FaYoutube} />
              </a>
            ) : e.includes("reddit") ? (
              <a href={e.trim()} target="_blank" rel="noopener noreferrer">
                <Icon as={FaReddit} />
              </a>
            ) : e.includes("medium") ? (
              <a href={e.trim()} target="_blank" rel="noopener noreferrer">
                <Icon as={FaMedium} />
              </a>
                            ) : (
                              ""
                            )}
                          </Box>
                        ))}
                      </Flex>
                    </Box>
                  ) : null}

                  <Flex
                    cursor="pointer"
                    onClick={handleReportClick}
                    gap="10px"
                    alignItems="center"
                    mt="27px"
                  >
                    <BsFillFlagFill style={{ color: "#CB0000" }} />
                    <Text fontSize="12px" lineHeight="20px" fontWeight="400">
                      Report this tool
                    </Text>
                  </Flex>
                </Box>
              </Box>
            </Box>

            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent color="#444" bg="#D9D9D9" borderRadius="10px">
                <ModalHeader fontSize="15px" lineHeight="24px" fontWeight="600">
                  Report
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody fontSize="13px" fontWeight="400" lineHeight="16px">
                  <Flex flexDirection="column" gap="10px">
                    <Text fontSize="13px" lineHeight="24px" fontWeight="400">
                      {" "}
                      We are always looking for ways to make AI Pedia the best
                      possible experience for our customers. Please submit any
                      suggestions you have on how we can improve our service to
                      better suit your needs or problem.
                    </Text>
                    <Flex gap="10px" alignItems="center">
                      <input
                        value="Information provided is not correct"
                        checked={
                          selectedValue ===
                          "Information provided is not correct"
                        }
                        onChange={(e) => setSelectedValue(e.target.value)}
                        type="radio"
                      />
                      <Text>Information provided is not correct </Text>
                    </Flex>
                    <Flex gap="10px" alignItems="center">
                      <input
                        value="Dubplicate"
                        checked={selectedValue === "Dubplicate"}
                        onChange={(e) => setSelectedValue(e.target.value)}
                        type="radio"
                      />
                      <Text>Duplicate</Text>
                    </Flex>
                    <Flex gap="10px" alignItems="center">
                      <input
                        value="Harmful"
                        checked={selectedValue === "Harmful"}
                        onChange={(e) => setSelectedValue(e.target.value)}
                        type="radio"
                      />
                      <Text>Harmful</Text>
                    </Flex>
                    <Flex gap="10px" alignItems="center">
                      <input
                        value="Yes"
                        checked={selectedValue === "Yes"}
                        onChange={(e) => setSelectedValue(e.target.value)}
                        type="radio"
                      />
                      <Text>Wrong creatives (Logo/Cover/Gallery)</Text>
                    </Flex>

                    <Input
                      onChange={(e) => setMore(e.target.value)}
                      mt="10px"
                      borderRadius="3px"
                      bg="#F8F8F8"
                      _placeholder={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "#929292",
                      }}
                      placeholder="  Please be more specific"
                    />
                  </Flex>

                  <Flex
                    mt="20px"
                    mb="10px"
                    textAlign="end"
                    justifyContent="left"
                  >
                    <Button
                      color="#444"
                      onClick={sendReport}
                      fontSize="15px"
                      lineHeight="24px"
                      fontWeight="600"
                      border="1px solid black"
                      bg="#F8F8F8"
                      padding="3px 10px"
                      borderRadius="3px"
                    >
                      Submit
                    </Button>
                  </Flex>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Stack>
        </Box>

        {/* Responsive Box For Small Screen */}
        <Box
          width={"100%"}
          // display={{ base: "flex", sm: "none" }}
          flexDirection={"column"}
          className={tool.mobileview}
          gap={5
          }
        >
          <Flex alignItems={"flex-start"}>
            <Box width={"30%"}>
              <ImageBackground imageUrl={imageUrl} alt={imageUrl} />
            </Box>
            <Flex flexDirection={"column"} gap={2} width={"70%"}>
            {isBase ? (
             <>
            <Text fontSize="24px" as="h1" fontWeight="600">
             {el.Title}
            </Text>
              <Text as="h2" fontSize="14px">{el?.Tagline}</Text>
              </>
          ) : null}
              <Flex
              flexDirection="row" // Use row direction for inline display
              alignItems="center"              
              justifyContent="start" // Align items to the start of the container              
              mt="7px"
              gap="8px"
              fontSize="12px"
              fontWeight="400"
              lineHeight="20px"
              wrap="wrap" // Add wrap for cases where the content does not fit in one line
              >
              <Text
              textAlign="center"              
              // border="1px"              
              // px="10px"              
              // borderRadius="12px"      
              color={font}        
              w="auto" // Set width to auto
              style={{
              cursor: "pointer",
                }}
                    onClick={() => {
                    handleCategoryItem(el?.Category[0]);
                    }}
                      >
              {el?.Category[0]}
            </Text>
  {el.Category.length > 1 && (
    <Text
      textAlign="center"
      // border="1px"
      // px="10px"
      // borderRadius="12px"
      color={font}
      w="auto" // Set width to auto
      style={{
        cursor: "pointer",
      }}
      onClick={() => {
        handleCategoryItem(el?.Category[1]);
      }}
    >
      {el?.Category[1]}
    </Text>
      )}
      </Flex>
      
          </Flex>
          </Flex>

          <Flex width={"100%"} justifyContent="space-between" alignItems="center">
          
            <Flex
              alignItems="center"
              gap="5px"
              fontSize={{ base: "12px", md: "16px" }}
              lineHeight="24px"
              fontWeight="400"
              paddingRight={3}
              paddingBlock={2}
              paddingLeft={0}
              // flexGrow={1}
            >
              {/* <Flex flexDirection="column" flexGrow={1} pr={2}> */}
              <Flex alignItems="center" flexGrow={1} pr={2}>
              <FaStar size={12} color={el?.rating > 0 ? "#ECBA67" : "rgb(192,192,192)"} />
              
              
              <Text fontSize="12px" lineHeight="12px" fontWeight="700" as="span" ml="2">
                {el?.rating || 0} ({review})
                              
                </Text>
              
            </Flex>

            {el.Pricing && (
            <Flex
              flexDirection="column"
              alignItems={"center"}
              gap="10px"
              fontSize={{ base: "12px", md: "16px" }}
              fontWeight="400"
              lineHeight="16px"
              paddingBlock={2}
              color={font}
              paddingInline={3}
              borderLeft={"1px solid gray"}
            >
              {el.Pricing === "Free" ? (
                <Flex alignItems="center" gap="5px">
                  {/* <AiFillGift /> */}
                  <Text>{el.Pricing}</Text>
                </Flex>
              ) : el.Pricing === "Free trial" ? (
                <Flex alignItems="center" gap="5px">
                  {/* <BsClockHistory /> */}
                  <Text>{el.Pricing}</Text>
                </Flex>
              ) : el.Pricing === "Freemium" ? (
                <Flex alignItems="center" gap="5px">
                  {/* <MdOutlineVerified /> */}
                  <Text>{el.Pricing}</Text>
                </Flex>
              ) : el.Pricing === "Paid" ? (
                <Flex alignItems="center" gap="5px">
                  {/* <AiFillDollarCircle /> */}
                  <Text>{el.Pricing}</Text>
                </Flex>
              ) : (
                ""
              )}
            </Flex>
            )}
               
               {el.price_amount && (  
            <Box
              paddingBlock={2}
              paddingInline={3}
              borderLeft={"1px solid gray"}
            >
              <Text
                fontSize={{ base: "12px", md: "16px" }}
                lineHeight="16px"
                fontWeight="400"
                color={font}
              >
                {el.price_amount.split(",")[0]}
              </Text>
            </Box>
            )}
            
            {el?.works_with && el?.works_with.length > 0 && (

            <Flex
              gap="7px"
              alignItems={"center"}
              paddingBlock={2}
              paddingInline={3}
              borderLeft={"1px solid gray"}
            >
              {el?.works_with && el?.works_with.map((e, i) => (
                <Box key={i}>
                  {e.includes("Spreadsheet") ? (
                    <img
                      alt="icon"
                      borderRadius="4px"
                      boxSize="12px"
                      src={excel} key={i}
                    />
                  ) : e.includes("Chatgpt(Plugin)") ? (
                    <img
                      alt="icon"
                      boxSize="12px"
                      width={"80%"}
                      src={chatgpt} key={i}
                    />
                  ) : e.includes("VS Code") ? (
                    <img alt="icon" boxSize="12px" width={"80%"} src={vscode} key={i} />
                  ) : e.includes("Github") ? (
                    <img alt="icon" boxSize="12px" width={"80%"} src={github} key={i} />
                  ) : e.includes("Mobile app") ? (
                    <img alt="icon" boxSize="12px" width={"80%"} src={mobile} key={i} />
                  ) : e.includes("Wordpress") ? (
                    <img
                      alt="icon"
                      boxSize="12px"
                      width={"80%"}
                      src={Wordpress} key={i}
                    />
                  ) : e.includes("Figma") ? (
                    <img alt="icon" boxSize="12px" width={"80%"} src={figma} key={i} />
                  ) : e.includes("Browser Extension") ? (
                    <img
                      alt="icon"
                      boxSize="12px"
                      width={"80%"}
                      src={browser} key={i}
                    />
                  ) : e.includes("Slack") ? (
                    <img alt="icon" boxSize="12px" width={"80%"} src={slack} key={i} />
                  ) : e.includes("Shopify") ? (
                    <img alt="icon" boxSize="12px" width={"80%"} src={sopify} key={i} />
                  ) : (
                    ""
                  )}
                </Box>
              ))}
            </Flex>
            )}


            
          </Flex>
          

          
          <Button onClick={() => setShowDetails(!showDetails)}>
        {showDetails ? 'Less' : 'More'}
      </Button>
      </Flex>
      <Box>

      
      {showDetails && (
        
          <Box className={tool.media}>
            {(el?.social_media && [...new Set(el?.social_media)].length > 0 && [...new Set(el?.social_media)][0] !== "") ? (
              <Box>
                {/* <Text
                  // mt="30px"
                  fontSize="13px"
                  lineHeight="24px"
                  fontWeight="600"
                >
                  Social media:
                </Text> */}
                <Flex
                  // mt="5px"
                  gap="5px"
                  fontStyle="italic"
                  fontWeight="400"
                  fontSize="20px"
                >
                  {el?.social_media.map((e, i) => (
                    <Box key={i}>
                      {e.includes("facebook") ? (
                        <Link to={e.trim()} target="_blank">
                          <Icon as={FaFacebook} />
                        </Link>
                      ) : e.includes("twitter") || e.includes("x.com") ? (
                        <Link to={e.trim()} target="_blank">
                          <Icon as={FaXTwitter} />

                        </Link>
                      ) : e.includes("instagram") ? (
                        <Link to={e.trim()} target="_blank">
                         <Icon as={FaInstagram} />
                        </Link>
                      ) : e.includes("linkedin") ? (
                        <Link to={e.trim()} target="_blank">
                          <Icon as={FaLinkedin} />
                        </Link>
                      ) : e.includes("youtube") ? (
                        <Link to={e.trim()} target="_blank">
                          <Icon as={FaYoutube} />
                        </Link>
                          ) : e.includes("reddit") ? (
                            <Link to={e.trim()} target="_blank">
                              <Icon as={FaReddit} />
                            </Link>
                          ) : e.includes("medium") ? (
                            <Link to={e.trim()} target="_blank">
                              <Icon as={FaMedium} />
                            </Link>
                      ) : (
                        ""
                      )}
                    </Box>
                  ))}
                </Flex>
              </Box>
            ) : null}
            
            
            {el.Discount && (
  <Box className={tool.fcate} paddingBlock={2} paddingInline={2} borderLeft={"1px solid gray"}>
    <Box>
      <Text fontSize="13px" lineHeight="24px" fontWeight="600">
        Discount
      </Text>
      <Flex
        flexDirection="column"
        alignItems="left"
        mt="7px"
        gap="8px"
        fontSize="12px"
        fontWeight="400"
        lineHeight="20px"
        w="fit-content"
      >
        <Text
          textAlign="center"
          border="1px"
          borderColor={"#e0e0e0"}
          px="10px"
          borderRadius="12px"
          w="fit-content"
          style={{
            cursor: "pointer",
          }}
        >
          {el.Discount}
        </Text>
      </Flex>
    </Box>
  </Box>
)}

                   

                    {/* {el?.Tags > 0 ? (
                      <Box>
                        <Text
                          mt="30px"
                          fontSize="13px"
                          lineHeight="24px"
                          fontWeight="600"
                        >
                          Tags:
                        </Text>
                        <Box
                          fontStyle="italic"
                          fontWeight="400"
                          fontSize="12px"
                        >
                          {el?.Tags.map((e, i) => (
                            <Text mt="5px" fontSize="14px" key={i}>
                              {e}
                            </Text>
                          ))}
                        </Box>
                      </Box>
                    ) : null}*/}
                  
                 

            <Flex
              cursor="pointer"
              onClick={handleReportClick}
              gap="10px"
              alignItems="center"
              // mt="27px"
              paddingBlock={2}
              paddingInline={3}
              borderLeft={"1px solid gray"}

            >
              <BsFillFlagFill style={{ color: "#CB0000" }} />
              <Text fontSize="12px" lineHeight="20px" fontWeight="400">
                Report this tool
              </Text>
            </Flex>
          </Box>

          )}
        </Box>
          <Flex width={"100%"} gap={4} alignItems={"center"}>
            <Box>
            <a href={el?.URL} target="_blank" >
                <Button
                  display={"flex"}
                  alignItems={"center"}
                  gap={2}
                  _hover={{ bg: "" }}
                  color="white"
                  borderRadius="5px"
                  fontSize="16px"
                  fontWeight="400"
                  bg="#3B89B6"
                  paddingInline={7}
                  aria-label="View Product Page"
                >
                  <BsBoxArrowUpRight /> Visit Site
                </Button>
              </a>
            </Box>

            <Box
              border="1px solid #e0e0e0"
              padding={2.5}
              borderRadius="4px"
              justifyContent="center"
            >
              <Likes el={el} />
            </Box>

            <Box>
              <Save el={el} id={id} />
            </Box>

            <ShareModel url={url} ShareText={"Share"} />
          </Flex>
        </Box>
                    
        <Stack className={tool.right}>
          <Box>
            <Box className={tool.titlehide1}>
              <Flex justifyContent="space-between" alignItems="center">
              {!isBase ? (
                <Text fontSize="24px"  as="h1" lineHeight="24px" fontWeight="600">
                  {el.Title}
                </Text>
                ) : null}
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontStyle="16px"
                  fontWeight="400"
                  lineHeight="24px"
                >
                  <Text>{el?.likes} People liked this</Text>
                  <Box
                    border="1px solid #e0e0e0"
                    padding="8px"
                    borderRadius="9px"
                    justifyContent="center"
                  >
                    <Likes el={el} />
                  </Box>
                </Flex>
              </Flex>
              {(el?.rating > 0 || review > 0) && (
                //  <>
              <Flex gap="10px" alignItems="center" mt="15px" mb="10px">
      {/* <Flex gap="4px">
        <ReactStars
          count={5}
          value={el.rating}
          size={14}
          edit={false}
          isHalf={true}
          emptyIcon={<FaStar color="rgb(192,192,192)" />}
          halfIcon={<FaStarHalfAlt color="#ECBA67" />}
          filledIcon={<FaStar color="#ECBA67" />}
        />
      </Flex> */}
     
     
      <Flex gap="4px">
        {getStars(el.rating)}
      </Flex>
      
      
                <Flex
                  alignItems="center"
                  gap="5px"
                  fontSize="15px "
                  lineHeight="24px"
                  fontWeight="400"
                >
                  <Text fontWeight="700">({el?.rating}) </Text>
                 <Text fontWeight="700">{review}</Text>

                  <Text>reviews</Text>
                </Flex>   
              </Flex>               
                //</>
            )}
            
            
              {!isBase ? (

              <Text
                mt="24px"
                mb="20px"
                fontSize="16px"
                fontWeight="400"
                lineHeight="24px"
                textAlign="justify"
                as="h2"
              >
                {el?.Tagline}
              </Text>
              ) : null}
            </Box>

            <Tabcomp el={el} id={id} setreview={setreview} key={id} />
          </Box>
        </Stack>
      </Box>
    </Box>)
};

