import { SearchIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Center,
  Flex,
  Grid,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import style from "../../Style/Collection.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
// import textStyle from "../../Style/List.module.css";
import LoadingAnimation from '../LoadingAnimation';

export const Curated = () => {
  let [data, setData] = useState([]);
  let [page, setPage] = useState(1);
  let [find, setfind] = useState("");
  const { colorMode, toggleColorMode } = useColorMode();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const borderColor = useColorModeValue("#e0e0e0", "#444");

  const font = useColorModeValue("gray.600", "gray.400");
  const heading = useColorModeValue("gray.800", "#eeeeee");

  const getData = async () => {
    setLoading(true);
  
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/space/get/All?search=${find}&page=${page}`
      );
  
      const fetchedData = res.data.data.space;
      console.log('Fetched Data:', fetchedData);
  
      const newData = fetchedData.filter(fetchedItem => 
        !data.some(existingItem => existingItem._id === fetchedItem._id)
      );
  
      console.log('New Data (filtered):', newData);
      setData(prevData => [...prevData, ...newData]);
      setHasMore(fetchedData.length > 0);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  
  

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + 1 < document.documentElement.scrollHeight || !hasMore) {
        return;
      }
      setPage(prev => prev + 1);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore]);

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    setPage(1); // Reset to first page
    setData([]); // Clear current data
    getData(); // Fetch new data based on search term
  }, [find]);


  // if (loading) {
  //   return (
  //     <div style={{ marginTop: '-80px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
  //       <LoadingAnimation size="xl" />
  //     </div>
  //   );
  // }

  return (
    <Box fontFamily="'Lato', Arial, Helvetica, sans-serif">
      <Text as="h2"textAlign={{ base: "left", md: "justify" }} className={style.colldesc} fontSize={{ base: "16px", md: "20px" }}mb="13px" py= "10px" color={heading} >
      Explore Top AI Tools: Ultimate Collections for Developers & Creatives
      </Text>

      
      <InputGroup w={{ base: "100%", md: "45%" }} alignItems="center">
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input
          onChange={(e) => setfind(e.target.value)}
          border="1px"
          borderColor={borderColor}
          placeholder="Search"
        />
      </InputGroup>
  

      <Grid
        width={"100%"}
        gridTemplateColumns={{ base: "repeat(1,1fr)", lg: "repeat(3,1fr)" }}
        gridTemplateRows={"auto"}
        columnGap={"50px"}
        rowGap={"30px"}
        marginTop={{ base: 7, md: 10 }}
      >
        {data?.map((el, i) => (
          <Link to={`/collection/curated-collection/${el.slug}`} key={i}>
           <Flex 
             width={"100%"} 
               gap={5} 
                 _hover={{ 
               "& .hover-grid": { // Targeting the Grid within Flex
                borderColor: '#42a4ff',
                boxShadow: '0 0 10px #42a4ff'
                  }
                }}
                >
            <Grid
          width="100px"
          gridTemplateColumns={"repeat(2,1fr)"}
          gridTemplateRows={"repeat(2, 1fr)"} // Make it a 2x2 grid for symmetry
          gap={3}
          backgroundColor={colorMode === "light" ? "#DAE7FA" : "#2f3f48"}
          borderRadius={5}
          padding={4}
          height={"100px"}
          //boxShadow={"0px 3px 6px rgba(0,0,0,0.16)"} // Subtle shadow for depth
          className="hover-grid"
        >
                {el?.tool[0]?.logoURL ? (
                  <Image
                    src={`${el?.tool[0].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"40px"}
                    height={"auto"}
                    alt={`1st Tool's logo from collection`}
                  />
                ) : (
                  // <Image
                  //   src={"https://www.svgrepo.com/show/530661/genetic-data.svg"}
                  //   borderRadius="4px"
                  //   width={"40px"}
                  //   height={"auto"}
                  // />
                  ""
                )}
                {el?.tool[1]?.logoURL ? (
                  <Image
                    src={`${el?.tool[1].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"40px"}
                    height={"auto"}
                    alt={`2nd Tool's logo from collection`}
                  />
                ) : (
                  // <Image
                  //   src={
                  //     "https://www.svgrepo.com/show/530439/api-interface.svg"
                  //   }
                  //   borderRadius="4px"
                  //   width={"40px"}
                  //   height={"auto"}
                  // />
                  ""
                )}
                {el?.tool[2]?.logoURL ? (
                  <Image
                    src={`${el?.tool[2].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"40px"}
                    height={"auto"}
                    alt={`3rd Tool's logo from collection`}
                  />
                ) : (
                  // <Image
                  //   src={
                  //     "https://www.svgrepo.com/show/530401/table-of-contents.svg"
                  //   }
                  //   borderRadius="4px"
                  //   width={"40px"}
                  //   height={"auto"}
                  // />
                  ""
                )}
                {el?.tool[3]?.logoURL ? (
                  <Image
                    src={`${el?.tool[3].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"40px"}
                    height={"auto"}
                    alt={`4th Tool's logo from collection`}
                  />
                ) : 
                  // <Image
                  //   src={"https://www.svgrepo.com/show/530225/cell-phone.svg"}
                  //   borderRadius="4px"
                  //   width={"40px"}
                  //   height={"auto"}
                  // />
                  ""
                }
       
              </Grid>

              

              <Flex
                width={{ base: "70%", md: "80%", lg: "70%" }}
                flexDirection={"column"}
                gap={2}
                alignItems={"flex-start"}
              >
                <Box>
                  <Text
                    fontSize={{ base: "15px", md: "15px" }}
                    fontWeight={600}
                    color={heading}
                  >
                    {el?.space_name}
                  </Text>
                </Box>

                <Flex
                  alignItems={{ base: "flex-start", md: "center" }}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  {/* <Box paddingRight={2}>
                    <Text
                      fontSize={{ base: "12px", md: "15px" }}
                      color={"#666666"}
                      fontWeight={600}
                    >
                      Created by
                    </Text>
                  </Box> */}

                  <Flex alignItems={"center"}>
                    <Flex alignItems={"center"} gap={2} >
                      <Box >
                      <Image boxSize="24px"  borderRadius="full" loading="lazy" alt={"Profile picture"}  src={el?.userID?.image } />
                      </Box>
                      <Text
                        fontSize={{ base: "12px", md: "12px" }}
                        fontWeight={600}
                      >
                        {`${el?.userID?.name}`.length > 8
                          ? `${el?.userID?.name}`.substring(0, 7) + ".."
                          : `${el?.userID?.name}`}
                      </Text>
                    </Flex>
                    <Text
                        fontSize={{ base: "12px", md: "12px" }}
                        fontWeight={600}
                        px={1}
                        color={"#3b89b6"}
                      >-</Text>

                    
                      <Text
                        fontSize={{ base: "12px", md: "12px" }}
                        fontWeight={600}
                        color={"#3b89b6"}
                      >
                        {el?.tool.length}{" "}tools 
                      </Text>
                  </Flex>
                </Flex>

                <Box display={{ base: "block", md: "none" }}>
                  <Text color={font} fontSize={{ base: "12px", md: "15px" }}>
                    {`${el?.description}`.length > 30
                      ? `${el?.description}`.substring(0, 30) + "..."
                      : `${el?.description}`}
                  </Text>
                </Box>

                <Box display={{ base: "none", md: "block" }}>
                  <Text color={font} fontSize={{ base: "12px", md: "15px" }}>
                    {`${el?.description}`.length > 55
                      ? `${el?.description}`.substring(0, 55) + "..."
                      : `${el?.description}`}
                  </Text>
                </Box>
              </Flex>        
          
            </Flex>
            
          </Link>
        ))}
              
        {loading && (
          <Flex justifyContent="center" width="100%">
            <LoadingAnimation size="xl" />
          </Flex>
        )}
      </Grid>
    </Box>
  );
};
