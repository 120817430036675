import {
  Box,
  Divider,
  Flex,
  Image,
  MenuList,
  Stack,
  Text,
  useColorModeValue,Avatar, useColorMode
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import style from "../Style/Featured.module.css";
import { HiOutlineBookmark } from "react-icons/hi";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setFilterCategory } from "../Redux/action";




const isMobileDevice = () => {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
};

export const CurtedCollection = ({ categoryData }) => {
  let [data, setData] = useState([]);
  //console.log("data: ", data);
  let [page, setPage] = useState(1);

  const { colorMode, toggleColorMode } = useColorMode();
  const heading = useColorModeValue("gray.900", "white");
  const font = useColorModeValue("gray.600", "gray.400");

  const getData = async () => {
    const cache = localStorage.getItem('cachedData');
    const cacheTime = localStorage.getItem('cacheTime');
  
    // Check if cache exists and is less than a month old
    if (cache && cacheTime && new Date().getTime() - new Date(cacheTime).getTime() < 30 * 24 * 60 * 60 * 1000) {
      setData(JSON.parse(cache));
    } else {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API}/space/get/All_home`);
        setData(res.data.data.space);
  
        // Cache new data with current timestamp
        localStorage.setItem('cachedData', JSON.stringify(res.data.data.space));
        localStorage.setItem('cacheTime', new Date().getTime().toString());
      } catch (err) {
        console.error(err);
      }
    }
  };
  
  useEffect(() => {
    if (!isMobileDevice()) {
      getData();
    }
  }, []);

  // const infinitScroll = async () => {
  //   try {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop + 1 >=
  //       document.documentElement.scrollHeight
  //     ) {
  //       setPage((prev) => prev + 1);
  //     }
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   // window.addEventListener("scroll", infinitScroll);
  //   // getCat();
  //   // return () => window.removeEventListener("scroll", infinitScroll);
  // }, []);



  const dispatch = useDispatch();

  const [cat, setcatlist] = useState([]);

  const getCat = () => {
    axios.get(`${process.env.REACT_APP_API}/cat/get`).then((res) => {
      setcatlist(res.data);
    });
  };

  const handleCat = (el, i) => {
    dispatch(setFilterCategory(el));
  };

  return (
    <div>
      <Box className={style.featured}>
        <Box w="100%">
          {/* <Flex
            alignItems="center"
            justifyContent="space-between"
            mt="30px"
            fontWeight="600"
            fontSize="13px"
            lineHeight="20px"
          >
            <Flex alignItems="center" gap="8px">
              <Text
                fontSize="17px"
                fontWeight="700"
                lineHeight="16px"
                textTransform="uppercase"
              >
                Categories
              </Text>
            </Flex>
          </Flex> */}

          {categoryData?.map((el, i) => (
            <Box
              mt="10px"
              key={i}
              w="100%"
              // display={el.tool.length >= 1 ? "block" : "none"}
            >
              <Text
              cursor="pointer"
              fontSize="15px"
              fontWeight="400"
              lineHeight="31.5px"
                onClick={() => {
                  handleCat(el, i);
                }}
              >
                {el}
              </Text>
              {/* <Divider border="1px" borderColor={"#e0e0e0"} /> */}

              {/* <Flex flexDirection="column">
                {el.Category.map((e, ind) => (
                  <Text
                    cursor="pointer"
                    fontSize="15px"
                    fontWeight="400"
                    lineHeight="31.5px"
                  >
                    {e}
                  </Text>
                ))}
              </Flex> */}
            </Box>
          ))}

          {/* <Divider
            border="1px"
            borderColor={useColorModeValue("#e0e0e0", "#444")}
            mt="30px"
          /> */}
        </Box>
      </Box>
      <Box className={style.featured}>
        <Box w="100%">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            mt="30px"
            fontWeight="600"
            fontSize="13px"
            lineHeight="20px"
          >
            <Flex alignItems="center" gap="8px">
              <Text color={heading} textTransform="uppercase">AI collections</Text>
              <HiOutlineBookmark size={15} />
            </Flex>

            <Link to="/collection">
              <Text
                fontSize="12px"
                lineHeight="25.5px"
                fontWeight="700"
                color="#3B89B6"
              >
                View all
              </Text>
            </Link>
          </Flex>
          {data?.slice(0, 5).map((el, i) => (
            <Box
              mt="30px"
              key={i}
              w="100%"
              display={el.tool.length >= 1 ? "block" : "none"}
            >
              <a href={`/collection/curated-collection/${el.slug}`}>
                <Flex alignItems="center" gap="10px" w="100%" h="100%">
      
                  <Box padding="5px"  backgroundColor={colorMode === "light" ? "#DAE7FA" : "#2f3f48"} className={style.grid_icon}>
                  {el?.tool?.slice(0, 4).map((toolItem, i) => (
                      <Box
                        key={i}
                        h="100%"
                        minWidth={el.tool.length <= 1 ? "50px" : ""}
                        minH={el.tool.length <= 2 ? "50px" : ""}
                       
                      >
                        {i <= 3 && (
                           <Image borderRadius="5px" padding="3px" maxWidth="fit-content" boxSize="25px" src={toolItem.logoURL} alt={`Tool ${i}`} />
                          // <Image borderRadius="5px"
                          // padding="3px"
                          // maxWidth="fit-content"
                          // boxSize="25px" src={`${e}?height=25`} key={i} />
                        )}
                      </Box>
                    ))}
                  </Box>

                  <Box>
                    <Text
                      fontSize="13px"
                      fontWeight="600"
                     
                      lineHeight="24px"
                      color={heading}
                    >
                      {el.space_name}
                    </Text>

                     <Flex
                  alignItems={{ base: "flex-start", md: "center" }}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  {/* <Box paddingRight={2}>
                    <Text
                      fontSize={{ base: "12px", md: "15px" }}
                      color={"#666666"}
                      fontWeight={600}
                    >
                      Created by
                    </Text>
                  </Box> */}

                  <Flex alignItems={"center"}>
                    <Flex alignItems={"center"} gap={2} >
                      <Box >
                      <Image boxSize="24px"  borderRadius="full" loading="lazy" alt={"Profile picture"}  src={el?.userID?.image } />
                      </Box>
                      <Text
                        fontSize={{ base: "12px", md: "12px" }}
                        fontWeight={600}
                      >
                        {`${el?.userID?.name}`.length > 8
                          ? `${el?.userID?.name}`.substring(0, 7) + ".."
                          : `${el?.userID?.name}`}
                      </Text>
                    </Flex>

                    <Text
                        fontSize={{ base: "12px", md: "12px" }}
                        fontWeight={600}
                        px={1}
                        color={"#3b89b6"}
                      >-</Text>

                    
                      <Text
                        fontSize={{ base: "12px", md: "12px" }}
                        fontWeight={600}
                        color={"#3b89b6"}
                      >
                        {el?.tool.length}{" "}tools 
                      </Text>
                 
                  </Flex>
                </Flex>
                    <Text
                      w="90%"
                      fontSize="12px"
                      lineHeight="20px"
                      className={style.desc}
                      color={font}
                    >
                      {el.description}{" "}
                    </Text>
                  </Box>
                </Flex>
                </a>

            </Box>
          ))}

          <Divider
            border="1px"
            borderColor={useColorModeValue("#e0e0e0", "#444")}
            mt="30px"
          />
        </Box>
      </Box>
    </div>
  );
};
