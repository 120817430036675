import {
  Avatar,
  Box,
  Flex,
  Grid,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import LoadingAnimation from '../LoadingAnimation';

export const MyCollection = () => {
  let [data, setData] = useState([]);
  let [page, setPage] = useState(1);
  const { colorMode, toggleColorMode } = useColorMode();

  const [loading, setLoading] = useState(false); // To track loading state
  const [hasMore, setHasMore] = useState(true);

  const font = useColorModeValue("gray.600", "gray.400");
  const heading = useColorModeValue("gray.800", "#eeeeee");

  const userData = useSelector((state) => state.userReducer.loginData);
  let token = userData.data;

  const getData = async (page) => {
    if (loading || !hasMore) return; // Don't fetch if already loading or no more data

    setLoading(true); // Set loading before the request
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}/space/get?page=${page}`, {
        headers: { token },
      });

      let fetchedData = res.data.data || [];
      if (fetchedData.length === 0) {
        setHasMore(false); // If no data is returned, we've reached the end
      } else {
        setData((prevData) => [...prevData, ...fetchedData]); // Append new data to the existing data
      }
    } catch (err) {
      // Handle error here
      setHasMore(false); // Assume no more data on error
    }
    setLoading(false); // Set loading after the request is finished
  };

  // Fetch initial data
  useEffect(() => {
    getData(page);
  }, [page]);

  // Infinite scroll logic
  useEffect(() => {
    const infinitScroll = async () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setPage((prev) => prev + 1);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", infinitScroll);
    return () => window.removeEventListener("scroll", infinitScroll);
  }, []); // No dependencies, this only needs to mount once


  // if (loading) {
  //   return (
  //     <div style={{ marginTop: '-80px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
  //       {/* <LoadingAnimation size="xl" /> */}
  //     </div>
  //   );
  // }

  return (
    <Grid
    width={"100%"}
    gridTemplateColumns={{ base: "repeat(1,1fr)", lg: "repeat(3,1fr)" }}
    gridTemplateRows={"auto"}
    columnGap={"50px"}
    rowGap={"30px"}
    marginTop={{ base: 7, md: 10 }}
  >
      {data?.map((el, i) => (
        <Link to={`/collection/my-collection/${el.slug}`} key={i}>
          <Flex 
             width={"100%"} 
               gap={5} 
                 _hover={{ 
               "& .hover-grid": { // Targeting the Grid within Flex
                borderColor: '#42a4ff',
                boxShadow: '0 0 10px #42a4ff'
                  }
                }}
                >
            <Grid
          width="100px"
          gridTemplateColumns={"repeat(2,1fr)"}
          gridTemplateRows={"repeat(2, 1fr)"} // Make it a 2x2 grid for symmetry
          gap={3}
          backgroundColor={colorMode === "light" ? "#DAE7FA" : "#2f3f48"}
          borderRadius={5}
          padding={4}
          height={"100px"}
          //boxShadow={"0px 3px 6px rgba(0,0,0,0.16)"} // Subtle shadow for depth
          className="hover-grid"
        >
                {el?.tool[0]?.logoURL ? (
                  <Image
                    src={`${el?.tool[0].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"40px"}
                    height={"auto"}
                    alt={`1st Tool's logo from collection`} 
                  />
                ) : (
                  // <Image
                  //   src={"https://www.svgrepo.com/show/530661/genetic-data.svg"}
                  //   borderRadius="4px"
                  //   width={"40px"}
                  //   height={"auto"}
                  // />
                  ""
                )}
                {el?.tool[1]?.logoURL ? (
                  <Image
                    src={`${el?.tool[1].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"40px"}
                    height={"auto"}
                    alt={`2nd Tool's logo from collection`} 
                  />
                ) : (
                  // <Image
                  //   src={
                  //     "https://www.svgrepo.com/show/530439/api-interface.svg"
                  //   }
                  //   borderRadius="4px"
                  //   width={"40px"}
                  //   height={"auto"}
                  // />
                  ""
                )}
                {el?.tool[2]?.logoURL ? (
                  <Image
                    src={`${el?.tool[2].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"40px"}
                    height={"auto"}
                    alt={`3rd Tool's logo from collection`} 
                  />
                ) : (
                  // <Image
                  //   src={
                  //     "https://www.svgrepo.com/show/530401/table-of-contents.svg"
                  //   }
                  //   borderRadius="4px"
                  //   width={"40px"}
                  //   height={"auto"}
                  // />
                  ""
                )}
                {el?.tool[3]?.logoURL ? (
                  <Image
                    src={`${el?.tool[3].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"40px"}
                    height={"auto"}
                    alt={`4th Tool's logo from collection`} 
                  />
                ) : 
                  // <Image
                  //   src={"https://www.svgrepo.com/show/530225/cell-phone.svg"}
                  //   borderRadius="4px"
                  //   width={"40px"}
                  //   height={"auto"}
                  // />
                  ""
                }
       
              </Grid>

 
              <Flex
                width={{ base: "70%", md: "80%", lg: "70%" }}
                flexDirection={"column"}
                gap={2}
                alignItems={"flex-start"}
              >
                <Box>
                  <Text
                    fontSize={{ base: "15px", md: "15px" }}
                    fontWeight={600}
                    color={heading}
                  >
                    {el?.space_name}
                  </Text>
                </Box>

                <Flex
                  alignItems={{ base: "flex-start", md: "center" }}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  {/* <Box paddingRight={2}>
                    <Text
                      fontSize={{ base: "12px", md: "15px" }}
                      color={"#666666"}
                      fontWeight={600}
                    >
                      Created by
                    </Text>
                  </Box> */}

                 <Flex alignItems={"center"}>
                    <Flex alignItems={"center"} gap={2} >
                      <Box >
                      <Image boxSize="24px"  borderRadius="full" loading="lazy" alt={"Profile picture"}  src={el?.userID?.image } />
                      </Box>
                      <Text
                        fontSize={{ base: "12px", md: "12px" }}
                        fontWeight={600}
                      >
                        {`${el?.userID?.name}`.length > 8
                          ? `${el?.userID?.name}`.substring(0, 7) + ".."
                          : `${el?.userID?.name}`}
                      </Text>
                    </Flex>
                    <Text
                        fontSize={{ base: "12px", md: "12px" }}
                        fontWeight={600}
                        px={1}
                        color={"#3b89b6"}
                      >-</Text>

                    
                      <Text
                        fontSize={{ base: "12px", md: "12px" }}
                        fontWeight={600}
                        color={"#3b89b6"}
                      >
                        {el?.tool.length}{" "}tools 
                      </Text>
                  </Flex>
                </Flex>

                <Box display={{ base: "block", md: "none" }}>
                  <Text color={font} fontSize={{ base: "12px", md: "15px" }}>
                    {`${el?.description}`.length > 30
                      ? `${el?.description}`.substring(0, 30) + "..."
                      : `${el?.description}`}
                  </Text>
                </Box>

                <Box display={{ base: "none", md: "block" }}>
                  <Text color={font} fontSize={{ base: "12px", md: "15px" }}>
                    {`${el?.description}`.length > 55
                      ? `${el?.description}`.substring(0, 55) + "..."
                      : `${el?.description}`}
                  </Text>
                </Box>
              </Flex>                 
            </Flex>            
          </Link>
        ))}
    </Grid>
  );
};
