import React from 'react';
import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Button,
  Link,
  Stack,
  VStack,
  Image,
  Icon,
  useColorModeValue,
  useBreakpointValue,
  chakra,
  Center,
} from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import lightLogo from "../Utils/LOGO-AIZONES-white.svg";
import darkLogo from "../Utils/LOGO-AIZONES-black.svg";
import {Helmet, HelmetProvider} from "react-helmet-async";

const AboutPage = () => {
  const logoLight = darkLogo;
  const logoDark = lightLogo;
  const logoSrc = useColorModeValue(logoLight, logoDark);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const primaryColor = useColorModeValue("blue.600", "blue.200");

   // loginData
   document.title = "About Us - AI Zones";

   // Set meta tags
   const metaTitle = "Unlock AI Potential: Explore Curated Collections & Build Your Dream AI Toolkit - AI Zones";
   const metaDescription = "Explore AI Zones, your ultimate AI tools directory. Curate personal collections, discover tools with AI-powered search, and connect with creators. Learn more about us!";
 

  return (
    <HelmetProvider>
    <Helmet>
      {/* Meta tags */}
      <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {/* Other meta tags, such as og:tags, can be added here */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}/about`} />
        <meta property="og:title" content={metaTitle} />            
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="AI Zones" />
        <meta property="og:image" content= '../Utils/AI-Tools-Collections-Coverimg-AIZones.png' />
        <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/collection`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`${process.env.REACT_APP_PUBLIC_URL}/about`} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content="https://ai-zones.b-cdn.net/Website-utilities%20/AI-Zones_Cover.png" />
  
    </Helmet>
    <Container maxW={isMobile ? "100%" : "50%"} mt={10}>
        <Box textAlign="center" px={10} mb={5} shadow="xl" rounded="md">
      <Center flexDirection="column">
       
          <Image src={logoSrc} alt="AI Zones Logo" boxSize={isMobile ? "150px" : "250px"} />
          <Heading as="h1" fontSize="4xl" fontWeight="bold" color={primaryColor} mb={2}>
            About AI Zones
          </Heading>
          <Text fontSize="lg" my={4}>
            Revolutionizing the discovery and utilization of AI tools.
          </Text>
        
        <VStack spacing={4} align="center" p={5}>
          <Text fontSize="lg" fontWeight="bold">
            Our Mission
          </Text>
          <Text fontSize="md" textAlign="center">
            At AI Zones, we are on a mission to revolutionize the way individuals and businesses discover and leverage artificial intelligence tools. Our platform offers the largest collection of AI tools online, designed to empower innovation and efficiency.
          </Text>
          <Text fontSize="lg" fontWeight="bold" mt={4}>
            Our Team
          </Text>
          <Text fontSize="md" textAlign="center">
            Behind AI Zones is a team of passionate AI aficionados, seasoned developers, and visionary designers, all committed to providing a user-friendly, informative, and engaging platform.
          </Text>
          <Text fontSize="lg" fontWeight="bold" mt={4}>
            Join Us
          </Text>
          <Text fontSize="md" textAlign="center">
            Discover, engage, and innovate with us in the exciting landscape of artificial intelligence.
          </Text>
          <Flex direction="column" align="center" mt={4}>
            <Button colorScheme="blue" size="lg" mb={5} onClick={() => (window.location.href = '/')}>
              Discover Tools
            </Button>
            <Link href="mailto:hello@aizones.io" isExternal display="flex" alignItems="center" mb={5} fontSize="lg">
              <EmailIcon mr={2} />hello@aizones.io
            </Link>
            <Stack direction="row" spacing={8}>
              {/* <Link href="https://facebook.com" isExternal><Icon as={FaFacebook} w={8} h={8} /></Link> */}
              <Link href="https://www.instagram.com/aizones.io/" isExternal><Icon as={FaInstagram} w={8} h={8} /></Link>
              <Link href="https://twitter.com/Aizones_io" isExternal><Icon as={FaTwitter} w={8} h={8} /></Link>
              <Link href="https://www.linkedin.com/company/ai-zones" isExternal><Icon as={FaLinkedin} w={8} h={8} /></Link>
            </Stack>
          </Flex>
        </VStack>
      </Center>
      </Box>
    </Container>
    </HelmetProvider>
    
  );
};

export default AboutPage;
